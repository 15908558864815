import { snowplowUser } from '@analytics/snowplow/events';
import { sentryCaptureException } from '@configs/sentry';
import AuthLocalStorage from '@local-storage/auth';
import SubscriptionLocalStorage from '@local-storage/subscription';
import UserLocalStorage from '@local-storage/user';
import RemoteLoadSubscription from '@remote-data/load-subscription';
import Auth from '@shared/auth';
import GlobalsCookie from '@shared/utils/cookie/globals';
import User from '@shared/utils/user';
import NetworkErrorOrCancelRequest from '@use-cases/errors/network-error-or-cancel-request';
import { joinSubscriptionInUser } from '@use-cases/sign-in/utils';

export const crossPlatform = async () => {
  if (unnecessaryRoutes()) {
    return false;
  }

  if (Auth.isTokenExpired(Auth.getToken())) {
    window.addEventListener('isLoadingRefreshToken', listener);
    return false;
  }

  return run();
};

function listener(e: CustomEvent) {
  if (e?.detail?.isLoadingRefreshToken) {
    return false;
  }

  return run();
}

const run = async () => {
  try {
    const isNeedUpdateLocalStorage = needUpdateLocalStorage();
    const isNeedUpdateGlobalsCookie = needCreateGlobalsCookie();

    if (isNeedUpdateLocalStorage || isNeedUpdateGlobalsCookie) {
      if (isNeedUpdateLocalStorage) {
        const { user, subscription } = await User.getUserAndSubscriptionAPI();
        updateLocalStorage(user, subscription);
      }

      if (isNeedUpdateGlobalsCookie) {
        updateGlobalsCookie();
      }
    }

    window.removeEventListener('isLoadingRefreshToken', listener);
  } catch (error: any) {
    const refreshTokenError = error instanceof NetworkErrorOrCancelRequest;

    if (!refreshTokenError) {
      sentryCaptureException(error, {
        tags: {
          section: 'crossPlatform',
        },
      });
    }
  }

  return false;
};

const unnecessaryRoutes = () =>
  window.location.pathname.includes('login') ||
  window.location.pathname.includes('cadastro') ||
  window.location.pathname.includes('planos') ||
  window.location.pathname === '/';

const needUpdateLocalStorage = (): boolean => {
  if (window.location.pathname.includes('home')) return false;

  const globals = GlobalsCookie.get();
  const userLocalStorage = UserLocalStorage.get();

  if (!globals && !userLocalStorage) return false;

  if (globals && !userLocalStorage) return true;

  return false;
};

const needCreateGlobalsCookie = (): boolean => {
  const globals = GlobalsCookie.get();

  if (!globals) return true;

  return false;
};

const updateLocalStorage = (user, subscription) => {
  UserLocalStorage.set(user, subscription);
  UserLocalStorage.setName(user.name);

  AuthLocalStorage.setToken(Auth.getToken());
  AuthLocalStorage.setRefreshToken(Auth.getRefreshToken());

  SubscriptionLocalStorage.setIsPremium(subscription?.isPremium);

  snowplowUser({
    ...user,
    degreeFormationType: user.formationDegree,
    subscription,
  });
};

const updateGlobalsCookie = async () => {
  try {
    const authToken = AuthLocalStorage.getToken();
    const refreshToken = AuthLocalStorage.getRefreshToken();

    const user = await User.getUserAPI(User.getId());

    const responseSubscription = await RemoteLoadSubscription.run(authToken);
    const subscription = responseSubscription?.body;

    const data = joinSubscriptionInUser({ ...user, authToken, refreshToken }, subscription);
    const cookieContent = GlobalsCookie.generateCookieContent(data);

    GlobalsCookie.set(cookieContent);
  } catch (error) {
    sentryCaptureException(error, {
      tags: {
        section: 'crossPlatform',
      },
    });
  }
};
