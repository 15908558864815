import { DrugInteractionTypes } from '@context/drug-interaction/actions';
import { DrugInteractionActionsEnum } from '@context/drug-interaction/types';
import { RegistrantDrugInteractionModelType } from '@models/drug-interaction/registrant-drug-interaction-model';

export const initialDrugInteractionState: RegistrantDrugInteractionModelType = {
  help: false,
  rating: false,
  activeIngredients: [],
  interactions: [],
};

export const drugInteractionReducer = (
  state: RegistrantDrugInteractionModelType = initialDrugInteractionState,
  action: DrugInteractionTypes,
) => {
  switch (action.type) {
    case DrugInteractionActionsEnum.SET_HELP:
      return { ...state, help: action.help };

    case DrugInteractionActionsEnum.SET_RATING:
      return { ...state, rating: action.rating };

    case DrugInteractionActionsEnum.SET_ACTIVE_INGREDIENTS:
      return { ...state, activeIngredients: action.activeIngredients };

    case DrugInteractionActionsEnum.SET_INTERACTIONS:
      return { ...state, interactions: action.interactions };

    default:
      return state;
  }
};
