import { KeysLocalStorageEnum } from '@local-storage/types';
import LocalStorage from '@local-storage/index';
import User from '@shared/utils/user';
import { OnboardingState } from '@screens/hooks/use-onboarding/types';

class Onboarding {
  get(): OnboardingState | null {
    const key = this.generateKey() as KeysLocalStorageEnum;

    if (!key) return null;

    return LocalStorage.get(key);
  }

  set(onboardingState: OnboardingState) {
    LocalStorage.set(this.generateKey() as KeysLocalStorageEnum, onboardingState);
  }

  getAll() {
    const allLocalStorage = Object.entries(localStorage);

    return allLocalStorage.filter(([key]) => {
      if (key.includes('ONBOARDING')) {
        return key;
      }
      return null;
    });
  }

  setAll(onboardingLocalStorage) {
    onboardingLocalStorage.forEach(([key, value]) => {
      LocalStorage.set(key, JSON.parse(value));
    });
  }

  generateKey(): string {
    const id = User?.getId();

    if (!id) return '';

    return `br.com.pebmed.whitebook.${id}.ONBOARDING`;
  }
}

export const OnboardingLocalStorage = new Onboarding();
