import Cookie from '@shared/utils/cookie';
import { GlobalsCookieModel } from '@models/globals-cookie-model';
import { merge } from 'lodash';
import { cookieDomain } from '@shared/utils';
import { UserModel } from '@remote-data/sign-in/types';

export const COOKIE_NAME = 'globals';
export const COOKIE_TIME_TO_EXPIRE_IN_SECONDS = 3600 * 240;

class GlobalsCookie extends Cookie {
  get(): GlobalsCookieModel {
    const getCookie = super.get(COOKIE_NAME);
    let cookieData = null;

    if (getCookie) {
      cookieData = JSON.parse(atob(getCookie));
    }

    return cookieData;
  }

  set(value) {
    super.set(COOKIE_NAME, this.encrypt(value), this.mountOptions());
  }

  update(cookieData: Partial<GlobalsCookieModel> = {}): void {
    const oldCookieData = this.get();

    const newCookieData = merge(oldCookieData, cookieData);

    this.set(newCookieData);
  }

  generateCookieContent(userModel: UserModel): GlobalsCookieModel {
    return {
      origin: `${process.env.NEXT_PUBLIC_APP_NAME}`,
      authData: userModel?.authToken,
      refreshToken: userModel?.refreshToken,
      currentUser: {
        id: userModel?.id,
        nome: userModel?.firstName,
        cpf: userModel?.cpf,
        email: userModel?.email,
        tipo: userModel?.userTypeId,
        cep: userModel?.address?.cep,
        id_formacao_medica: userModel?.medicalFormationId,
        id_especialidade: userModel?.specialtyId,
        grau_formacao: userModel?.degreeFormationType,
        id_area_atuacao: userModel?.areaExpertiseId,
        num_conselho_profis: userModel?.professionalCouncilNumber,
      },
      subscription: {
        ativa: userModel?.isSubscription,
        premium: {
          ...userModel?.subscription,
        },
        freeTasting: {
          ...userModel?.freeTasting,
        },
        lastChecked: userModel?.lastChecked,
      },
    };
  }

  clear() {
    super.clear(COOKIE_NAME, {
      path: '/',
      domain: cookieDomain(),
    });
  }

  private encrypt(cookieData) {
    return btoa(JSON.stringify(cookieData));
  }

  private mountOptions() {
    return {
      path: '/',
      maxAge: COOKIE_TIME_TO_EXPIRE_IN_SECONDS,
      domain: cookieDomain(),
      secure: false,
      httpOnly: false,
    };
  }
}

export default new GlobalsCookie();
