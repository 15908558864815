import * as Sentry from '@sentry/react';
import UserLocalStorage from '@local-storage/user';
import GlobalsCookie from '@shared/utils/cookie/globals';
import User from '@shared/utils/user';
import AuthLocalStorage from '@local-storage/auth';
import { isDevelopment, isLocalhost } from '@shared/utils';
import { HttpStatusCode } from '@remote-data/http-client/types';

export const initSentry = () => {
  Sentry.init({
    integrations: [new Sentry.BrowserTracing()],
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_APP_VERSION,
    ignoreErrors: [
      'top.GLOBALS',
      'NetworkError when attempting to fetch resource.',
      'Failed to fetch',
      'fbq is not defined',
      'Non-Error promise rejection captured',
    ],
    enableTracing: true,
    tracesSampleRate: isDevelopment() ? 1 : 0.01,
    sampleRate: 1,
    beforeSend: (event) => {
      if (isLocalhost()) {
        return null;
      }

      addMoreData(event);

      return event;
    },
  });
};

/* eslint-disable no-param-reassign */
const addMoreData = (event) => {
  const { userId, user } = userData();
  const { device } = deviceData();

  event.user = { ...event.user, ...userId };

  event.extra = {
    ...event.extra,
    user,
    device,
  };
};

const userData = () => {
  const userLocalStorage = UserLocalStorage.get();
  const globalsCookie = GlobalsCookie.get();
  const tokenLocalStorage = AuthLocalStorage.getToken();
  const refreshTokenLocalStorage = AuthLocalStorage.getRefreshToken();

  const id = userLocalStorage ? userLocalStorage?.id : globalsCookie?.currentUser?.id;
  const userId = { id: id ? id.toString() : id };

  return {
    userId,
    user: {
      premium: User.isUserPremium() ? User.isUserPremium() : null,
      type: userLocalStorage ? userLocalStorage.formationDegree : null,
      hasUserLocalStorage: userLocalStorage ? !!userLocalStorage : false,
      hasGlobalsCookie: globalsCookie ? !!globalsCookie : false,
      hasToken: tokenLocalStorage ? !!tokenLocalStorage : false,
      hasRefreshToken: refreshTokenLocalStorage ? !!refreshTokenLocalStorage : false,
    },
  };
};

const deviceData = () => {
  // @ts-ignore
  const effectiveType = navigator?.connection?.effectiveType || null;
  // @ts-ignore
  const rtt = navigator?.connection?.rtt || null;

  return {
    device: {
      screen: {
        height: window?.screen?.height || null,
        width: window?.screen?.width || null,
      },
      window: {
        height: window?.innerHeight || null,
        width: window?.innerWidth || null,
      },
      document: {
        readyState: document.readyState,
        url: document.location.href || null,
        referrer: document.referrer || null,
      },
      connection: {
        effectiveType,
        rtt,
      },
      performance: performance?.getEntriesByType('navigation')[0] || null,
      localDateTime: Date.now() || null,
    },
  };
};

export const sentryCaptureException = (exception, params?: { tags?; extra?; contexts?; user?; level? }) => {
  if (
    exception?.statusCode === HttpStatusCode.Unauthorized ||
    exception?.statusCode === HttpStatusCode.Forbidden ||
    exception?.statusCode === HttpStatusCode.NotFound ||
    exception?.statusCode === HttpStatusCode.Conflict
  )
    return;

  Sentry.captureException(exception, { ...params });
};

export const sentryCaptureMessage = (message: string, params?: { tags?; extra?; contexts?; user?; level? }) => {
  Sentry.captureMessage(message, { ...params });
};
