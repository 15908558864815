import AuthLocalStorage from '@local-storage/auth';
import { DecodedTokenModel } from '@models/decoded-token-model';
import { isProduction } from '@shared/utils';
import { addMilliseconds, differenceInMilliseconds, millisecondsToMinutes, subMilliseconds } from 'date-fns';

export const normalizeTimeToRefreshToken = (jwtToken: string) => {
  const decodedPayload = decodeToken(jwtToken);

  if (typeof decodedPayload === 'boolean') return false;

  const expirationDate = decodedPayload.exp * 1000;

  const difference = calculateDifferenceTime(decodedPayload);

  if (difference >= 0 && isToleranceMinutes(difference)) return false;

  const newTimeToRefreshToken = calculateNewTimeToRefreshToken(expirationDate, difference);

  if (newTimeToRefreshToken) {
    setNewTimeToRefreshToken(newTimeToRefreshToken);
    return true;
  }

  return false;
};

export const decodeToken = (jwtToken: string): DecodedTokenModel | boolean => {
  if (!jwtToken) return false;

  const encodedPayload = jwtToken.split('.')[1];

  if (!encodedPayload) return false;

  const decodedPayload = Buffer.from(encodedPayload, 'base64').toString('ascii');

  return JSON.parse(decodedPayload);
};

export const calculateDifferenceTime = (decodedPayload: DecodedTokenModel) => {
  const createdDate = decodedPayload.iat * 1000;
  const userDate = new Date();

  return differenceInMilliseconds(userDate, new Date(createdDate));
};

export const calculateNewTimeToRefreshToken = (expirationDate: number, differenceTime: number) => {
  if (differenceTime < 0) {
    const convertToPositiveNumber = Math.abs(differenceTime);
    return subMilliseconds(new Date(expirationDate), convertToPositiveNumber);
  }

  return addMilliseconds(new Date(expirationDate), differenceTime);
};

const setNewTimeToRefreshToken = (newTimeToRefreshToken: Date) => {
  AuthLocalStorage.setNewTimeToRefreshToken(newTimeToRefreshToken);
};

export const isToleranceMinutes = (differenceTime: number): boolean => {
  const toleranceMinutes = isProduction() ? 15 : 3;
  return millisecondsToMinutes(differenceTime) <= toleranceMinutes;
};
