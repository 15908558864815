import { OfferCommunicationsType } from '@hooks/use-remote-config/types/offer-communications';

export const defaultOfferCommunicationsValues: { web_offer_communications: OfferCommunicationsType } = {
  web_offer_communications: {
    enabled: false,
    footer: {
      data: [
        {
          location: '',
          enabled: false,
          title: '',
          button: {
            icon: '',
            text: '',
            width: '',
            height: '',
          },
          subtitle: '',
        },
      ],
    },
    header: {
      data: [
        {
          location: '',
          enabled: false,
          title: '',
          titleResponsive: '',
          button: {
            icon: '',
            text: '',
            width: '',
            height: '',
          },
          showCommunicationCategoryId: 0,
        },
      ],
    },
    ad: {
      excludeCommunicationCategoryIds: [],
      data: [
        {
          location: '',
          enabled: false,
          title: '',
          button: {
            icon: '',
            text: '',
            width: '',
            height: '',
          },
          url: '',
        },
      ],
    },
  },
};
