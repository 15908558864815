export enum OffsetActionsEnum {
  SET_OFFSET = '@offset/SET',
}

export type OffsetModel = {
  hero: HTMLElement;
  features: HTMLElement;
  testimonials: HTMLElement;
  isDark: boolean;
};

export type OffsetActionsType = {
  type: OffsetActionsEnum;
  payload: OffsetModel;
};
