import { createContext, useMemo, useReducer } from 'react';

import { initialState, mainReducer } from '@context/root-reducers';
import { ApplicationStateType } from '@context/root-reducers/types';

const AppStateProvider = createContext<{
  state: Partial<ApplicationStateType>;
  dispatch;
}>({
  state: null,
  dispatch: null,
});

export function ContextProvider({ children }) {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return <AppStateProvider.Provider value={contextValue}>{children}</AppStateProvider.Provider>;
}

export { AppStateProvider as ContextConsumer };
