import { cookieDomain } from '@shared/utils';
import { Cookies } from 'react-cookie';

export default class Cookie {
  get(name) {
    return new Cookies().get(name);
  }

  set(name, value, options) {
    new Cookies().set(name, value, options);
  }

  clear(name, options) {
    new Cookies().remove(name, options);
  }

  clearAll() {
    document.cookie.split(';').forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/;domain=${cookieDomain()};`);
    });
  }
}
