import { GeneralSearchActionTypes } from '@context/general-search/actions';
import { loggerInfo } from '@shared/logger';
import { GeneralSearchActionsEnum, GeneralSearchState, GeneralSearchTabs } from '@context/general-search/types';

export const initialGeneralSearchState: GeneralSearchState = {
  searchTerm: '',
  selectedTab: GeneralSearchTabs.CONTENT,
  openSearchInput: false,
};

export const generalSearchReducer = (
  state: GeneralSearchState = initialGeneralSearchState,
  action: GeneralSearchActionTypes,
) => {
  loggerInfo('GeneralSearchContext', 'generalSearchReducer', { state, action });
  switch (action.type) {
    case GeneralSearchActionsEnum.SEARCH_TERM:
      return { ...state, searchTerm: action.searchTerm };
    case GeneralSearchActionsEnum.SELECTED_TAB:
      return { ...state, selectedTab: action.selectedTab };
    case GeneralSearchActionsEnum.OPEN_SEARCH_INPUT:
      return { ...state, openSearchInput: action.openSearchInput };
    default:
      return state;
  }
};
