import Head from 'next/head';

export default function LoginTags() {
  return (
    <Head>
      <title>Whitebook Clinical Decision</title>
      <meta name="description" content="Faça o seu login agora mesmo no whitebook." />
    </Head>
  );
}
