export enum FavoritesTabActionsEnum {
  SELECTED_TAB = '@favorites-tab/SELECTED_TAB',
}

export enum FavoritesTab {
  CONTENT = 'content',
  CLINICAL_DROPS = 'clinical-drops',
  CODE = 'code',
}

export type FavoritesTabState = {
  selectedTab?: FavoritesTab;
};
