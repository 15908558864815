const stringFy = (object?: object | null): string => {
  try {
    return JSON.stringify(object, null, 2);
  } catch (error) {
    loggerWarn('UsefulService', 'stringFy', error);
    throw error;
  }
};

export const loggerInfo = (fileName: string, functionName: string, data?, display = false) => {
  if (!display) return;

  if (process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'DEVELOPMENT') {
    const dateNow = new Date().toLocaleTimeString();
    if (data) {
      console.info(`${dateNow} -> [${fileName}] -> ${functionName}() -> data = `, stringFy(data));
    } else {
      console.info(`${dateNow} -> [${fileName}] -> ${functionName}()`);
    }
  }
};

export const loggerWarn = (fileName: string, functionName: string, error) => {
  if (process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'DEVELOPMENT') {
    const dateNow = new Date().toLocaleTimeString();
    console.warn(`${dateNow} -> [${fileName}] -> ${functionName}() -> error = `, stringFy(error));
  }
};
