// Swagger
// http://api-usuario-homologacao.pebmed.com.br/docs/#/Cadastro/get_usuario_dados

import { RemoteError } from '@remote-data/errors';
import AxiosHttpClient from '@remote-data/http-client';
import { HttpClient, HttpResponse, HttpStatusCode } from '@remote-data/http-client/types';
import { responseFromUserDataModel } from '@remote-data/user-data/adapter';
import { loggerInfo } from '@shared/logger';

const makeApiUrl = (path: string): string => `${process.env.NEXT_PUBLIC_API_USER}${path}`;
const REMOTE_NAME_LOGGER = 'RemoteUserData';

export class RemoteUserData {
  constructor(private readonly url: string, private readonly httpClient: HttpClient) {}

  async run(userId: number): Promise<HttpResponse> {
    loggerInfo(REMOTE_NAME_LOGGER, 'run', { userId });
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?id=${userId}`,
      method: 'GET',
    });

    if (httpResponse.statusCode === HttpStatusCode.Ok) {
      return responseFromUserDataModel(httpResponse);
    }
    throw new RemoteError(httpResponse.statusCode, httpResponse.body.codigo, httpResponse.body.mensagem);
  }
}

export default new RemoteUserData(makeApiUrl('/usuario/dados'), AxiosHttpClient);
