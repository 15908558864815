import { SubHeaderPersistentActionTypes } from '@context/sub-header-persistent/actions';
import { SubHeaderPersistentActionsEnum, SubHeaderPersistentState } from '@context/sub-header-persistent/types';

export const initialSubHeaderPersistentState: SubHeaderPersistentState = { reduceSize: false };

export const subHeaderPersistentReducer = (
  state: SubHeaderPersistentState = initialSubHeaderPersistentState,
  action: SubHeaderPersistentActionTypes,
) => {
  switch (action.type) {
    case SubHeaderPersistentActionsEnum.SET_REDUCE_SIZE:
      return { ...state, reduceSize: action.reduceSize };
    default:
      return state;
  }
};
