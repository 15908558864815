export enum SignUpLoginActionsEnum {
  SET_IS_SIGN_UP = '@signUpLogin/SET_IS_SIGN_UP',
  SET_LOGIN_VALUE = '@signUpLogin/SET_LOGIN_VALUE',
  SET_HIGHLIGHT_SIGN_IN = '@signUpLogin/SET_HIGHLIGHT_SIGN_IN',
  SET_LOGIN_METHOD = '@signUpLogin/SET_LOGIN_METHOD',
}

export type SignUpLoginState = {
  isSignUp?: boolean;
  loginValue?: string;
  highlightSignIn?: boolean;
  loginMethod?: string;
};
