import { IInsertScriptAttributes } from '@shared/utils/dom/types';

export function insertScriptOnTheDOM(scriptAttributes: IInsertScriptAttributes) {
  const existingScript = document.getElementById(scriptAttributes.id);

  if (!existingScript) {
    const script = document.createElement('script');
    Object.keys(scriptAttributes).forEach((attribute) => {
      script.setAttribute(attribute, scriptAttributes[attribute]);
    });
    document.body.appendChild(script);
  }
}
