import LocalStorage from '@local-storage/index';
import { KeysLocalStorageEnum } from '@local-storage/types';
import UseCaseCheckSubscription from '@use-cases/check-subscription';
import UseCaseUserData from '@use-cases/user-data';
import GlobalsCookie from '@shared/utils/cookie/globals';
import UserLocalStorage from '@local-storage/user';

export interface IUserLocalStorage {
  id: number;
  profile: number;
  email: string;
  typeId: number;
  subscriptionId: number;
  medicalFormationId: number;
  occupationArea: string;
  specialty: string;
  specialtyId: number;
  formationDegree: string;
  graduationYear: number;
  uf: string;
  registerDate: string;
  occupationAreaId: number;
}

class User {
  async getUserAPI(userId: number) {
    const user = await UseCaseUserData.run(userId);
    return user;
  }

  async getSubscriptionAPI() {
    const subscription = await UseCaseCheckSubscription.run();
    return subscription;
  }

  async getUserAndSubscriptionAPI() {
    const subscription = await this.getSubscriptionAPI();
    const user = await this.getUserAPI(subscription.userId);
    user.userTypeId = this.checkActiveSubscription(subscription.id);
    return {
      user,
      subscription,
    };
  }

  isUserPremium() {
    const globals = GlobalsCookie.get();
    return !!globals?.subscription?.ativa || !!LocalStorage.get(KeysLocalStorageEnum.SUBSCRIPTION_USER);
  }

  checkActiveSubscription(subscriptionId: number) {
    return subscriptionId ? 1 : 2;
  }

  getId() {
    return GlobalsCookie.get()?.currentUser?.id || UserLocalStorage.get()?.id;
  }
}
export default new User();
