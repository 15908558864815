import Head from 'next/head';

export default function GeneralTags() {
  return (
    <Head>
      <link rel="icon" type="image/png" href="/favicon.png" />
      <meta
        property="og:title"
        content="Informação médica atualizada a qualquer hora e a qualquer lugar, agora no seu computador!"
        key="ogTitle"
      />
      <meta property="og:image" content="img/wb.png" />
      <meta httpEquiv="content-language" content="pt-br" />
      <meta
        name="keywords"
        content="aplicativo médico,mhealth,ehealth,medicina digital,whitebook,clinical decision,decisão clínica"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Head>
  );
}
