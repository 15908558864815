import { SearchCID10ActionTypes } from '@context/search-cid10/actions';
import { loggerInfo } from '@shared/logger';
import { SearchCID10State, SearchCID10ActionsEnum } from './types';

export const initialSearchCID10State: SearchCID10State = {
  results: [],
  loading: false,
  searchTerm: '',
};

export const searchCID10Reducer = (
  state: SearchCID10State = initialSearchCID10State,
  action: SearchCID10ActionTypes,
) => {
  loggerInfo('SearchCID10Context', 'searchCID10Reducer', { state, action });
  switch (action.type) {
    case SearchCID10ActionsEnum.LOAD_RESULTS:
      return { ...state, results: action.results };
    case SearchCID10ActionsEnum.SET_LOADING:
      return { ...state, loading: action.loading };
    case SearchCID10ActionsEnum.CHANGE_SEARCH_TERM:
      return { ...state, searchTerm: action.searchTerm };
    default:
      return state;
  }
};
