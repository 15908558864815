import RegistrantSignUpModelType from '@models/registrant-sign-up-model';

export enum FormActionsEnum {
  SET_FORMATION_DEGREE = '@form/SET_FORMATION_DEGREE',
  SET_NAME = '@form/SET_NAME',
  SET_BIRTH_DATE = '@form/SET_BIRTH_DATE',
  SET_NATIONALITY = '@form/SET_NATIONALITY',
  SET_WORK_COUNTRY = '@form/SET_WORK_COUNTRY',
  SET_UF = '@form/SET_UF',
  SET_CPF = '@form/SET_CPF',
  SET_CELLPHONE = '@form/SET_CELLPHONE',
  SET_ALLOW_COMMUNICATIONS = '@form/SET_ALLOW_COMMUNICATIONS',
  SET_EMAIL = '@form/SET_EMAIL',
  SET_CONFIRMATION_EMAIL = '@form/SET_CONFIRMATION_EMAIL',
  SET_PASSWORD = '@form/SET_PASSWORD',
  SET_CONFIRMATION_PASSWORD = '@form/SET_CONFIRMATION_PASSWORD',
  SET_OCCUPATION_AREA = '@form/SET_OCCUPATION_AREA',
  SET_GRADUATION_YEAR = '@form/SET_GRADUATION_YEAR',
  SET_SPECIALTY = '@form/SET_SPECIALTY',
  SET_MEDICAL_FORMATION = '@form/SET_MEDICAL_FORMATION',
  SET_REGISTER_NUMBER = '@form/SET_REGISTER_NUMBER',
  SET_COLLEGE = '@form/SET_COLLEGE',
  SET_CRM_VERIFIED = '@form/SET_CRM_VERIFIED',
  RESET = '@form/RESET',
  SET_PROFILE_DATA_EDIT = '@form/SET_PROFILE_DATA_EDIT',
}

export type FormActionsType = {
  type: FormActionsEnum;
  payload: RegistrantSignUpModelType;
};
