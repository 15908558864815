import { OffsetActionsEnum, OffsetModel } from '@context/offset/types';
import { ApplicationActionType } from '@context/root-reducers/types';
import { loggerInfo } from '@shared/logger';

export const initialOffsetState: OffsetModel = {
  hero: null,
  features: null,
  testimonials: null,
  isDark: false,
};

export const offsetReducer = (
  state: OffsetModel = initialOffsetState,
  action: ApplicationActionType<OffsetActionsEnum, OffsetModel>,
) => {
  loggerInfo('offsetContext', 'offsetReducer', { state, action });
  if (action.type === OffsetActionsEnum.SET_OFFSET) {
    return { ...state, ...action.payload };
  }
  return state;
};
