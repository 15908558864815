import { getEntities, getSchema } from '@analytics/snowplow';
import LocalStorage from '@local-storage/index';
import { KeysLocalStorageEnum } from '@local-storage/types';
import { trackPageView, trackSelfDescribingEvent } from '@snowplow/browser-tracker';

type keyEntities = 'user' | 'category' | 'topic' | 'prescription' | 'experiment' | null;

interface HelpersReturn {
  track(platform: string, event: string, value, excludeEntities?: string[]): boolean;
  entity(platform: string, entity: string, value): void;
  clear(key: keyEntities): void;
  sendPageView(): void;
}

export const analyticsHelper = (): HelpersReturn => {
  const track = (platform: string, event: string, value, excludeEntities?: string[]): boolean => {
    if (platform !== 'snowplow') return false;

    let entities = getEntities();

    if (excludeEntities) {
      const regex = new RegExp(excludeEntities.join('|'), 'i');
      entities = entities.filter((entity) => !regex.test(entity.schema));
    }

    trackSelfDescribingEvent({
      event: {
        schema: getSchema(event),
        data: value,
      },
      context: entities,
    });
    return true;
  };

  const entity = (platform: string, entityLabel: string, value): void => {
    if (platform === 'snowplow') {
      if (entity != null) {
        const store = LocalStorage.get(KeysLocalStorageEnum.SNOWPLOW) || {};
        store[entityLabel] = value;

        LocalStorage.set(KeysLocalStorageEnum.SNOWPLOW, store);
      }
    }
  };

  const clear = (key: keyEntities): void => {
    if (!key) update(null);
    update(key);
  };

  const update = (context: keyEntities) => {
    const snowplow = localStorage.getItem('snowplow');
    if (context != null && snowplow) {
      const store = JSON.parse(snowplow);
      store[context] = {};
      localStorage.setItem('snowplow', JSON.stringify(store));
    } else {
      LocalStorage.set(KeysLocalStorageEnum.SNOWPLOW, {
        user: {},
        category: {},
        topic: {},
        experiment: {},
        prescription: {},
      });
    }
  };

  const sendPageView = () => {
    const entities = getEntities();
    trackPageView({
      context: entities,
    });
  };

  return { track, entity, clear, sendPageView };
};
