import { CodeContentActionTypes } from '@context/code-contents/actions';
import { CodeContentActionsEnum } from '@context/code-contents/types';
import { DataCode } from '@remote-data/load-codes/types';

export const initialCodeContentState: DataCode = {
  id: 0,
  type: 'CID10',
  code: '',
  title: '',
  chapter: '',
  rating: '',
  description: '',
  group: '',
  subgroup: '',
  tags: '',
};

export const codeContentReducer = (
  state: DataCode = initialCodeContentState,
  action: CodeContentActionTypes,
): DataCode => {
  switch (action.type) {
    case CodeContentActionsEnum.SET_CID10:
      return { ...action.content };

    case CodeContentActionsEnum.SET_SUS:
      return { ...action.content };

    case CodeContentActionsEnum.SET_TUSS:
      return { ...action.content };

    case CodeContentActionsEnum.SET_INITIALSTATE:
      return initialCodeContentState;

    default:
      return state;
  }
};
