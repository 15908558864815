import { DataCode } from '@remote-data/load-codes/types';

export enum SearchCID10ActionsEnum {
  LOAD_RESULTS = '@searchCID10/LOAD_RESULTS',
  SET_LOADING = '@searchCID10/SET_LOADING',
  CHANGE_SEARCH_TERM = '@searchCID10/CHANGE_SEARCH_TERM',
}

export type SearchCID10State = {
  results?: DataCode[];
  loading?: boolean;
  searchTerm?: string;
};
