import { KeysLocalStorageEnum } from '@local-storage/types';
import LocalStorage from '@local-storage/index';
import User from '@shared/utils/user';
import { YellowSeptemberState } from '@local-storage/yellow-september/types';

class YellowSeptemberLocalStorage {
  get(): YellowSeptemberState | null {
    const key = this.generateKey() as KeysLocalStorageEnum;

    if (!key) return null;

    return LocalStorage.get(key);
  }

  set(yellowSeptemberState: YellowSeptemberState) {
    LocalStorage.set(this.generateKey() as KeysLocalStorageEnum, yellowSeptemberState);
  }

  getAll() {
    const allLocalStorage = Object.entries(localStorage);

    return allLocalStorage.filter(([key]) => {
      if (key.includes('YELLOW_SEPTEMBER')) {
        return key;
      }
      return null;
    });
  }

  setAll(yelowSeptemberLocalStorage) {
    yelowSeptemberLocalStorage.forEach(([key, value]) => {
      LocalStorage.set(key, JSON.parse(value));
    });
  }

  generateKey(): string {
    const id = User?.getId();

    if (!id) return '';

    return `br.com.pebmed.whitebook.${id}.YELLOW_SEPTEMBER`;
  }
}

export const yellowSeptemberLocalStorage = new YellowSeptemberLocalStorage();
