import { ExperimentsActionTypes } from '@context/experiments/actions';
import { ExperimentsActionsEnum, ExperimentsState } from '@context/experiments/types';

export const initialExperimentsState: ExperimentsState = { data: [], cookieCreated: false };

export const experimentsReducer = (
  state: ExperimentsState = initialExperimentsState,
  action: ExperimentsActionTypes,
) => {
  switch (action.type) {
    case ExperimentsActionsEnum.SET_EXPERIMENTS:
      return { ...state, data: action.payload };
    case ExperimentsActionsEnum.SET_COOKIE_CREATED:
      return { ...state, cookieCreated: action.payload };
    default:
      return state;
  }
};
