import { SearchCodesActionTypes } from '@context/search-codes/actions';
import { loggerInfo } from '@shared/logger';
import { SearchCodesState, SearchCodesActionsEnum } from '@context/search-codes/types';

export const initialSearchCodesState: SearchCodesState = {
  results: [],
  loading: false,
  searchTerm: '',
  codeType: null,
};

export const searchCodesReducer = (
  state: SearchCodesState = initialSearchCodesState,
  action: SearchCodesActionTypes,
) => {
  loggerInfo('SearchCodesContext', 'SearchCodesReducer', { state, action });
  switch (action.type) {
    case SearchCodesActionsEnum.LOAD_RESULTS:
      return { ...state, results: action.results };
    case SearchCodesActionsEnum.SET_LOADING:
      return { ...state, loading: action.loading };
    case SearchCodesActionsEnum.CHANGE_SEARCH_TERM:
      return { ...state, searchTerm: action.searchTerm };
    default:
      return state;
  }
};
