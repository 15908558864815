import { CarouselDrawerContentActionsEnum } from '@context/carousel-drawer-content/types';
import { CarouselDrawerContentProps } from '@screens/home/components/carousel-drawer-content/types';
import { ApplicationActionType } from '@context/root-reducers/types';
import { loggerInfo } from '@shared/logger';

export const initialCarouselContentDrawer: CarouselDrawerContentProps = {
  isOpen: false,
  url: null,
};

export const carouselDrawerContentReducer = (
  state: CarouselDrawerContentProps = initialCarouselContentDrawer,
  action: ApplicationActionType<CarouselDrawerContentActionsEnum, CarouselDrawerContentProps>,
) => {
  loggerInfo('carouselDrawerContentContext', 'carouselDrawerContentReducer', { state, action });
  if (action.type === CarouselDrawerContentActionsEnum.SET_DRAWER_CONTENT) {
    return { ...state, ...action.payload };
  }
  return state;
};
