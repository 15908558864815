import { useEffect, useState } from 'react';
import { redirectRoutes } from '@components/redirect-pages/routes';
import Auth from '@shared/auth';
import useIsMobile from '@hooks/use-is-mobile';

export default function RedirectPages({ children }) {
  const [isRedirect, setIsRedirect] = useState(false);
  const isMobile = useIsMobile();

  const loginRoute = (url) => {
    if (Auth.isLoggedIn()) {
      redirect(url);
    }
  };

  const landingPageRoute = (url) => {
    if (Auth.isLoggedIn() && !isMobile) {
      redirect(url);
    }
  };

  const redirect = (url) => {
    setIsRedirect(true);
    window.location.href = url;
  };

  useEffect(() => {
    const currentPathname = window.location.pathname;
    const pathnameWithoutTrailingSlash = currentPathname.replace(/\/$/, '');
    const hasRoute = redirectRoutes.find((route) => route.from === pathnameWithoutTrailingSlash);

    if (hasRoute) {
      switch (hasRoute.from) {
        case '/login':
          loginRoute(hasRoute.to);
          break;
        case '':
        case '/planos':
          landingPageRoute(hasRoute.to);
          break;
        default:
          redirect(hasRoute.to);
          break;
      }
    }
  }, []);

  return !isRedirect && children;
}
