import User from '@shared/utils/user';

export const joinSubscriptionInUser = (userModel, userSubscriptionModel) => {
  return {
    ...userModel,
    subscription: userSubscriptionModel.subscription,
    freeTasting: userSubscriptionModel.freeTasting,
    lastChecked: userSubscriptionModel.lastChecked,
    isSubscription: userSubscriptionModel.subscription.active || userSubscriptionModel.freeTasting.active,
    userTypeId: User.checkActiveSubscription(userSubscriptionModel.subscription.id),
    plan: userSubscriptionModel.subscription?.plan?.storeId || '',
  };
};
