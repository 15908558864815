import { ApprovedExperimentsType } from '@hooks/use-remote-config/types/approved-experiments';

export const defaultApprovedExperimentsValues: { web_approved_experiments: ApprovedExperimentsType } = {
  web_approved_experiments: {
    data: [
      {
        enabled: false,
        cod_experimento: '',
        data_fim: '',
        descricao: '',
        options: '',
        variants: [
          {
            descricao: '',
          },
        ],
      },
    ],
  },
};
