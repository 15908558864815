import { HttpStatusCode } from '@remote-data/http-client/types';
import RemoteUserData, { RemoteUserData as RemoteUserDataType } from '@remote-data/user-data';
import { UserDataReduxModel } from '@remote-data/user-data/types';
import { loggerInfo } from '@shared/logger';

import { handleError } from '@use-cases/errors/error-handling';

const USE_CASE_NAME_LOGGER = 'UseCaseUserData';

export class UseCaseUserData {
  constructor(private readonly remoteUserData: RemoteUserDataType) {}

  async run(userId: number): Promise<UserDataReduxModel> {
    try {
      const response = await this.remoteUserData.run(userId);
      loggerInfo(USE_CASE_NAME_LOGGER, 'run', { response });
      return response.body;
    } catch (error) {
      return handleError(error, USE_CASE_NAME_LOGGER, [
        { code: HttpStatusCode.Forbidden, message: 'Por favor, faça login novamente.' },
      ]);
    }
  }
}

export default new UseCaseUserData(RemoteUserData);
