import { loggerInfo } from '@shared/logger';
import { FavoritesTab, FavoritesTabActionsEnum, FavoritesTabState } from '@context/favorites-tab/types';
import { FavoritesTabActionTypes } from '@context/favorites-tab/actions';

export const initialFavoritesTabState: FavoritesTabState = {
  selectedTab: FavoritesTab.CONTENT,
};

export const favoritesTabReducer = (
  state: FavoritesTabState = initialFavoritesTabState,
  action: FavoritesTabActionTypes,
) => {
  loggerInfo('FavoritesTabContext', 'favoritesTabReducer', { state, action });
  switch (action.type) {
    case FavoritesTabActionsEnum.SELECTED_TAB:
      return { ...state, selectedTab: action.selectedTab };
    default:
      return state;
  }
};
