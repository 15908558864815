import { KeysLocalStorageEnum } from '@local-storage/types';
import LocalStorage from '@local-storage/index';

class SubscriptionLocalStorage {
  async setIsPremium(isPremium: boolean) {
    LocalStorage.set(KeysLocalStorageEnum.SUBSCRIPTION_USER, isPremium);
  }

  async setAvailableForTrial(availableForTrial: boolean) {
    LocalStorage.set(KeysLocalStorageEnum.AVAILABLE_FOR_TRIAL, availableForTrial);
  }

  getAvailableForTrial() {
    return LocalStorage.get(KeysLocalStorageEnum.AVAILABLE_FOR_TRIAL);
  }
}

export default new SubscriptionLocalStorage();
