// Swagger
// https://api-assinatura-homologacao.pebmed.com.br/docs/#/V2/get_assinatura_v2_subscriptions

import { RemoteError } from '@remote-data/errors';
import AxiosHttpClient from '@remote-data/http-client';
import { HttpClient, HttpResponse, HttpStatusCode } from '@remote-data/http-client/types';
import { apiSubscriptionToModel, makeNotSubscriptionModel } from '@remote-data/load-subscription/adapters';
import { UserSubscriptionModel } from '@remote-data/load-subscription/types';
import { createQueryString } from '@shared/helpers';

const makeApiUrl = (path: string): string => `${process.env.NEXT_PUBLIC_API_ASSINATURA}${path}`;
const productWhitebookId = 1;

export class RemoteLoadSubscription {
  constructor(private readonly url: string, private readonly httpClient: HttpClient) {}

  async run(token = '', retrieveSubscriptionList = true): Promise<HttpResponse<UserSubscriptionModel>> {
    const query = createQueryString({
      product_id: productWhitebookId,
      ...(retrieveSubscriptionList && { retrieve_subscription_list: true }),
    });

    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${query}`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (HttpStatusCode.Ok === httpResponse.statusCode) {
      return apiSubscriptionToModel(httpResponse);
    }
    if (HttpStatusCode.NotFound === httpResponse.statusCode) {
      return makeNotSubscriptionModel(httpResponse);
    }
    throw new RemoteError(
      httpResponse.statusCode,
      httpResponse.body?.codigo || httpResponse.body?.code,
      httpResponse.body?.mensagem || httpResponse.body?.message,
    );
  }
}

export default new RemoteLoadSubscription(makeApiUrl('/assinatura/v2/subscriptions'), AxiosHttpClient);
