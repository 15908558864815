import { YellowSeptemberType } from '@hooks/use-remote-config/types/yellow-september';

export const yellowSeptemberValues: { web_yellow_september: YellowSeptemberType } = {
  web_yellow_september: {
    enabled: true,
    data: {
      limit_date: '28-08-2024',
      content_bottom_sheet: {
        title: 'Título do drawer',
        sub_title: 'Sub título do drawer',
        text_info: 'Texto do drawer',
        url_image: '/images/yellow-september/are-you-ok.png',
        title_button: 'Está tudo bem?',
      },
      url_button_bottom_bar: 'https://www.afya.com.br/saude-mental',
    },
  },
};
