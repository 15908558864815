import useWindowSize, { IWindowSize } from '@hooks/use-window-size';

export const DESKTOP_MIN_WIDTH = 1140;

const useIsMobile = (size = DESKTOP_MIN_WIDTH): boolean => {
  const windowSize: IWindowSize = useWindowSize();

  return windowSize.width < size;
};

export default useIsMobile;
