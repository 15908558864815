import { HttpResponse } from '@remote-data/http-client/types';
import { UserDataReduxModel } from '@remote-data/user-data/types';
import { ProfileModelFromAPI } from '@remote-data/user-profile/types';

export const responseFromUserDataModel = ({
  statusCode,
  body,
}: HttpResponse<ProfileModelFromAPI>): HttpResponse<UserDataReduxModel> => {
  return {
    statusCode,
    body: {
      id: body.id,
      name: body.nome,
      profileId: body.perfil_usuario,
      email: body.email,
      userTypeId: body.id_tipo_usuario,
      areaExpertiseId: body.id_area_atuacao,
      medicalFormationId: body.id_formacao_medica,
      specialtyId: body.id_especialidade,
      formationDegree: body.grau_formacao,
      graduation: body.graduacao,
      registerDate: body.data_cadastro,
      areaExpertise: body.area_atuacao,
      specialty: body.especialidade,
      cellphone: body.telefone,
      surname: body.sobrenome,
      birthDate: body.nascimento,
      app_codigo: body.app_codigo,
      nationalityId: body.id_pais_nacionalidade,
      allowCommunications: !!body.optedin,
    },
  };
};
