import { RemoteConfigActionTypes } from '@context/remote-config/actions';
import { RemoteConfigActionsEnum, RemoteConfigState } from '@context/remote-config/types';

export const initialRemoteConfigState: RemoteConfigState = { data: null };

export const remoteConfigReducer = (
  state: RemoteConfigState = initialRemoteConfigState,
  action: RemoteConfigActionTypes,
) => {
  switch (action.type) {
    case RemoteConfigActionsEnum.SET_DATA:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
