import { HttpStatusCode } from '@remote-data/http-client/types';
import AlreadyReportedError from '@use-cases/errors/already-reported-error';
import GoneError from '@use-cases/errors/gone-error';
import InternalServerError from '@use-cases/errors/internal-server-error';
import InvalidCredentialsError from '@use-cases/errors/invalid-credentials-error';
import InvalidParameterError from '@use-cases/errors/invalid-parameter-error';
import ParameterNotFoundError from '@use-cases/errors/parameter-not-found-error';
import UnexpectedError from '@use-cases/errors/unexpected-error';
import ConflictError from '@use-cases/errors/conflict-error';
import { loggerWarn } from '@shared/logger';
import IsNotAllowedError from '@use-cases/errors/is-not-allowed';
import PaymentRequired from '@use-cases/errors/payment-required';
import NetworkErrorOrCancelRequest from '@use-cases/errors/network-error-or-cancel-request';
import { GENERIC_ERROR_MESSAGE } from '@shared/constants/errors';

export interface Error {
  statusCode: number;
  message: string;
  code: string;
}

export interface CustomMessage {
  code: number;
  message: string;
}

const findMessageByCode = (errorList: CustomMessage[], error: Error) => {
  const found = errorList.find((item) => item.code === error.statusCode);
  return found ? found.message : error.message;
};

const WHATSAPP_CX = '(21) 97376-4379';
export const NETWORK_ERROR_OR_CANCELED_REQUEST_MESSAGE = `Ocorreu um problema com as sua conexão de internet e/ou sessão. Atualize a página, caso o erro persista, faça login novamente ou entre em contato conosco no WhatsApp ${WHATSAPP_CX}`;

export const handleError = (
  error: Error,
  useCaseName: string,
  customMessages: CustomMessage[],
  unexpectedErrorMessage?: string,
) => {
  loggerWarn(useCaseName, 'run', error || unexpectedErrorMessage);
  switch (error.statusCode) {
    case HttpStatusCode.Unauthorized:
      throw new InvalidCredentialsError(findMessageByCode(customMessages, error), error.code);
    case HttpStatusCode.Forbidden:
      throw new IsNotAllowedError(findMessageByCode(customMessages, error));
    case HttpStatusCode.NotFound:
      throw new ParameterNotFoundError(findMessageByCode(customMessages, error));
    case HttpStatusCode.NonProcessableEntity:
      throw new InvalidParameterError(findMessageByCode(customMessages, error));
    case HttpStatusCode.AlreadyReported:
      throw new AlreadyReportedError(findMessageByCode(customMessages, error));
    case HttpStatusCode.Gone:
      throw new GoneError(findMessageByCode(customMessages, error));
    case HttpStatusCode.Conflict:
      throw new ConflictError(findMessageByCode(customMessages, error));
    case HttpStatusCode.ServerError:
      throw new InternalServerError(findMessageByCode(customMessages, error) || GENERIC_ERROR_MESSAGE);
    case HttpStatusCode.PaymentRequired:
      throw new PaymentRequired(findMessageByCode(customMessages, error));
    case undefined:
      throw new NetworkErrorOrCancelRequest(NETWORK_ERROR_OR_CANCELED_REQUEST_MESSAGE);
    default:
      throw new UnexpectedError(findMessageByCode(customMessages, error) || GENERIC_ERROR_MESSAGE);
  }
};
