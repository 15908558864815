import { AxiosError, AxiosRequestConfig } from 'axios';
import { HttpRequest, HttpStatusCode } from '@remote-data/http-client/types';
import Auth from '@shared/auth';
import { getBrowserNome, getBrowserVersion } from '@shared/utils';
import User from '@local-storage/user';

export const refreshUrl = `${process.env.NEXT_PUBLIC_API_ACESSO}/refresh`;

export const isUnauthorizedStatus = (error: AxiosError) => error?.response?.status === HttpStatusCode.Unauthorized;

export const exceptionPaths = () =>
  window.location.pathname.includes('login') ||
  window.location.pathname.includes('cadastro') ||
  window.location.pathname.includes('parceiro') ||
  window.location.pathname.includes('planos') ||
  window.location.pathname === '/';

export const isExternalAPI = (url: string) => !(url.search('pebmed.com.br') > -1 && url.search('api-') > -1);

export const isAuthRequest = (url: string) => {
  const pathname = url.split('?')[0];

  return pathname.endsWith('/login') || pathname.endsWith('/refresh') || pathname.endsWith('/authenticate');
};

export const dispatchEvent = (value: boolean) => {
  window.dispatchEvent(new CustomEvent('isLoadingRefreshToken', { detail: { isLoadingRefreshToken: value } }));
};

export const isMedicineAPI = (url: string) => url.split('?')[0].endsWith('/medicamentos');

export const canCallRefreshToken = (url: string, alreadyCalledRefreshToken: boolean) => {
  if (alreadyCalledRefreshToken) return false;

  const accessToken = Auth.getToken();
  const refreshToken = Auth.getRefreshToken();
  const isAccessTokenActive = accessToken && !Auth.isTokenExpired(accessToken);
  const isInternalOrAuthRequest = isExternalAPI(url) || isAuthRequest(url);

  return refreshToken && !isAccessTokenActive && !isInternalOrAuthRequest && !exceptionPaths() && !isMedicineAPI(url);
};

export const setHeaders = (data: AxiosRequestConfig, requestData: HttpRequest) => {
  const { useCustomToken } = requestData;
  const token = data.url === refreshUrl ? Auth.getRefreshToken() : Auth.getToken();
  const browserName = getBrowserNome();
  const browserVersion = getBrowserVersion();
  const headers = {
    'X-User-Id': User.get()?.id || '',
    'X-Release': `${process.env.NEXT_PUBLIC_APP_VERSION}`,
    'X-App-Name': `whitebook_web`,
    'X-App-Version': `${process.env.NEXT_PUBLIC_APP_VERSION}`,
    'X-OS-Version': `${browserName}-${browserVersion}`,
  };

  return {
    ...headers,
    ...data.headers,
    Authorization: token && !useCustomToken ? `Bearer ${token}` : data.headers?.Authorization,
  };
};
