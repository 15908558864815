export enum KeysLocalStorageEnum {
  TOKEN_AUTH = 'br.com.pebmed.whitebook.TOKEN_AUTH',
  NAME_USER = 'br.com.pebmed.whitebook.NAME_USER',
  SUBSCRIPTION_USER = 'br.com.pebmed.whitebook.SUBSCRIPTION_USER',
  SNOWPLOW = 'snowplow',
  LOGIN_REDIRECT = 'br.com.pebmed.whitebook.LOGIN_REDIRECT',
  SIGNUP_REDIRECT = 'br.com.pebmed.whitebook.SIGNUP_REDIRECT',
  CONTENT_CATEGORY_ID = 'br.com.pebmed.whitebook.CONTENT_CATEGORY_ID',
  USER = 'br.com.pebmed.whitebook.USER',
  FAKE_DOOR_CATEGORY = 'br.com.pebmed.whitebook.FAKE_DOOR_CATEGORY',
  FIRST_PAYWALL = 'br.com.pebmed.whitebook.FIRST_PAYWALL',
  USER_LOGIN_DATA = 'br.com.pebmed.whitebook.USER_LOGIN_DATA',
  AUTH = 'br.com.pebmed.whitebook.AUTH',
  AVAILABLE_FOR_TRIAL = 'br.com.pebmed.whitebook.AVAILABLE_FOR_TRIAL',
  TIME_TO_REFRESH_TOKEN = 'br.com.pebmed.whitebook.TIME_TO_REFRESH',
  YELLOW_SEPTEMBER = 'br.com.pebmed.whitebook.YELLOW_SEPTEMBER',
}
