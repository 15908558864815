import { PlanModel } from '@remote-data/load-offers/types';

export const CPFMask = (value: string) => {
  let newValue = value.replace(/\D/g, '');
  newValue = newValue.replace(/^(\d{3})(\d)/g, '$1.$2');
  newValue = newValue.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');
  newValue = newValue.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4');
  newValue = newValue.replace(/^(\d{3})\.(\d{3})\.(\d{3})\/(\d{2})(\d)/, '$1.$2.$3-$4');
  return newValue.substring(0, 14);
};

export const creditCardMask = (value: string) => {
  const newValue = value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
  return newValue;
};

export const cepMask = (value: string) => {
  let newValue = value.replace(/\D/g, '');
  newValue = newValue.replace(/(\d{5})(\d)/, '$1-$2');
  return newValue;
};

export const phoneMask = (value: string) => {
  let newValue = value.replace(/\D/g, '');
  if (newValue.length > 2) newValue = newValue.replace(/(\d{2})/, '($1)');
  newValue = newValue.replace(/\((\d{2})\)/, '($1) ');
  newValue = newValue.replace(/\((\d{2})\)\s(\d{5})/, '($1) $2');
  newValue = newValue.replace(/\((\d{2})\)\s(\d{5})(\d{4})/, '($1) $2-$3');
  return newValue.substring(0, 15);
};

export const birthDateMask = (value: string) => {
  const newValue = value.substring(0, 10);

  return newValue.split('-').reverse().join('/');
};

export const expiryDateMask = (value: string) => {
  let newValue = value.replace(/\D/g, '');
  newValue = newValue.replace(/(\d{2})(\d{2})/, '$1/$2');
  return newValue;
};

export const createQueryString = (params: object): string => {
  return Object.entries(params)
    .map(([key, value]) => {
      if (value === undefined || value === null) return null;
      return `${key}=${value}`;
    })
    .filter((value) => value)
    .join('&');
};

export const limitCaracteres = (text: string, count: number, insertDots = true): string => {
  const formattedText = text.slice(0, count);
  const dots = text.length > count && insertDots ? '...' : '';

  return `${formattedText}${dots}`;
};

export const applyMaskToEmail = (rawEmail: string): string => {
  const operandPosition = rawEmail.indexOf('@');
  const stringToReplace = rawEmail.slice(1, operandPosition);
  const replacedText = stringToReplace.replaceAll(/./g, '*');
  return rawEmail.replace(stringToReplace, replacedText);
};

export const removeMaskCpf = (cpf: string): string => cpf.replace(/[.|-]/gm, '');

export const onlyDigits = (value) => {
  return value.replace(/\D/g, '');
};

export const capitalizeString = (value: string): string => {
  return value?.toLowerCase().replace(/(?:^|\s)\S/g, (l) => l.toUpperCase()) || '';
};

export const removeAccentFromString = (value: string): string => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const formatNumberToCurrencyBRL = (number: number): string => {
  const formattedNumber = new Intl.NumberFormat('pt-Br', { style: 'currency', currency: 'BRL' }).format(number);

  return formattedNumber;
};

export const removeAccents = (str: string) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const orderPlansByPrice = (plans: PlanModel[]) => {
  const sorted = plans.sort((planA, planB) => {
    const valueA = planA.price.total.replace(',', '.');
    const valueB = planB.price.total.replace(',', '.');
    return Number(valueA) - Number(valueB);
  });
  return sorted;
};
