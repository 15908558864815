import { HttpRequest, HttpResponse } from '@remote-data/http-client/types';
import axios, { AxiosResponse } from 'axios';

import AuthLocalStorage from '@local-storage/auth';
import {
  canCallRefreshToken,
  dispatchEvent,
  exceptionPaths,
  isExternalAPI,
  isUnauthorizedStatus,
  refreshUrl,
  setHeaders,
} from '@remote-data/axios-request/utils';
import Auth from '@shared/auth';
import { normalizeTimeToRefreshToken } from '@shared/auth/utils';
import GlobalsCookie from '@shared/utils/cookie/globals';
import { AbortControllerInstance } from '@shared/utils/abort-controller';

let alreadyCalledRefreshToken = false;

export const axiosRequest = async (request: HttpRequest): Promise<HttpResponse> => {
  let axiosResponse: AxiosResponse;

  requestInterceptors(request);
  responseInterceptors();

  try {
    if (canCallRefreshToken(request.url, alreadyCalledRefreshToken)) {
      await tryRefreshToken();
    } else {
      if (alreadyCalledRefreshToken) return Promise.resolve(null);
      axiosResponse = await makeRequestAPI(request);
    }
  } catch (error) {
    if (isUnauthorizedStatus(error) && !exceptionPaths() && !isExternalAPI(error?.request?.responseURL)) {
      logoutAndRedirect();
    }

    axiosResponse = error.response;
  }

  return {
    statusCode: axiosResponse?.status,
    body: axiosResponse?.data,
  };
};

const requestInterceptors = (requestData: HttpRequest) => {
  axios.interceptors.request.use(async (request) => {
    if (!isExternalAPI(request.url)) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      request.headers = setHeaders(request, requestData);
    }

    return request;
  });
};

const responseInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const isAxiosError = axios.isAxiosError(error);

      if (isAxiosError && canCallRefreshToken(error.request.responseURL, alreadyCalledRefreshToken)) {
        await tryRefreshToken();
      }

      return Promise.reject(error);
    },
  );
};

const tryRefreshToken = async () => {
  if (!alreadyCalledRefreshToken) {
    alreadyCalledRefreshToken = true;
    AuthLocalStorage.clearNewTimeToRefreshToken();

    await makeRefreshToken();
  }

  return Promise.resolve(true);
};

const makeRequestAPI = async (data: HttpRequest) => {
  return axios.request({
    url: data.url,
    method: data.method,
    data: data.body,
    headers: data.headers,
    ...(AbortControllerInstance.get() && {
      signal: AbortControllerInstance.signal(),
    }),
  });
};

// @ts-ignore
// eslint-disable-next-line
const makeRefreshToken = async () => {
  axios
    .request({
      method: 'post',
      url: refreshUrl,
      headers: {
        Authorization: `Bearer ${Auth.getRefreshToken()}`,
      },
    })
    .then(({ data }) => {
      dispatchEvent(true);
      updateStorages(data.data);
    })
    .catch(() => {
      logoutAndRedirect();
    })
    .finally(() => {
      alreadyCalledRefreshToken = false;
      setTimeout(() => {
        dispatchEvent(false);
      }, 1);
    });
};

const updateStorages = async ({ accessToken, refreshToken }) => {
  AuthLocalStorage.setRefreshToken(refreshToken);
  AuthLocalStorage.setToken(accessToken);
  GlobalsCookie.update({ authData: accessToken, refreshToken });
  normalizeTimeToRefreshToken(accessToken);
};

const logoutAndRedirect = () => {
  Auth.logout();
  window.location.href = '/login';
};
