import { ReactNode, useEffect } from 'react';

import { ResetStyles } from '@layout/style';
import { PageHeaderTypes, PageLayoutTypes } from '@layout/types';
import dynamic from 'next/dynamic';
import { useRemoteConfig } from '@hooks/use-remote-config';

const mapLayoutToComponent = {
  [PageLayoutTypes.FreeContent]: dynamic(() => import('@layout/FreeContent')),
  [PageLayoutTypes.Logged]: dynamic(() => import('@layout/Logged')),
  [PageLayoutTypes.LpRenewal]: dynamic(() => import('@layout/LpRenewal')),
  [PageLayoutTypes.Polices]: dynamic(() => import('@layout/Polices')),
};

const mapHeaderToComponent = {
  [PageHeaderTypes.Landing]: dynamic(() => import('@components/header')),
  [PageHeaderTypes.ForgotPass]: dynamic(() => import('@components/header-form')),
  [PageHeaderTypes.FreeContent]: dynamic(() => import('@components/header-free-content')),
  [PageHeaderTypes.SignUp]: dynamic(() => import('@components/header-sign-up')),
  [PageHeaderTypes.Logged]: dynamic(() => import('@components/header-logged-in')),
  [PageHeaderTypes.DrugInteraction]: dynamic(() => import('@components/header-drug-interaction')),
  [PageHeaderTypes.Diagnosis]: dynamic(() => import('@components/header-diagnosis')),
  [PageHeaderTypes.LpRenewal]: dynamic(() => import('@screens/lp-renewal/components/header')),
  [PageHeaderTypes.HeaderShared]: dynamic(() => import('@screens/lp-renewal/shared/components/header')),
  [PageHeaderTypes.Polices]: dynamic(() => import('@components/header-polices')),
  [PageHeaderTypes.Switch]: dynamic(() => import('@components/header-switch')),
  [PageHeaderTypes.Empty]: () => {
    return null;
  },
};

export default function Layout({ children, layout, header }: { children: ReactNode; layout: string; header: string }) {
  const SpecificPageLayout = mapLayoutToComponent[layout] || mapLayoutToComponent[PageLayoutTypes.FreeContent];
  const SpecificPageHeader = mapHeaderToComponent[header] || mapHeaderToComponent[PageHeaderTypes.Landing];

  const { fetchValues } = useRemoteConfig();

  useEffect(() => {
    fetchValues();
  }, []);

  return (
    <>
      <ResetStyles />
      <SpecificPageHeader />
      <SpecificPageLayout>{children}</SpecificPageLayout>
    </>
  );
}
