import { ApplicationActionType } from '@context/root-reducers/types';
import { ContentModel } from '@models/content-model';
import { loggerInfo } from '@shared/logger';

import { CategoryContentsActionsEnum, CategoryContentsState } from './types';

export const initialCategoryContentsState: CategoryContentsState = {
  category: null,
  content: null,
  searchTerm: '',
  sectionOffset: {},
  loading: true,
};

const buildContentInSubCategory = (
  state: CategoryContentsState,
  contentPayload: ContentModel,
): CategoryContentsState => {
  const fullContent = {
    ...contentPayload,
  };
  state.category?.subCategories?.forEach((subCategory) => {
    subCategory.contents.forEach((content) => {
      if (content?.id === contentPayload?.id) {
        fullContent.category = state.category;
        fullContent.subcategory = subCategory;
      }
    });
  });
  return {
    ...state,
    content: fullContent,
  };
};

export const categoryContentsReducer = (
  state: CategoryContentsState = initialCategoryContentsState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: ApplicationActionType<CategoryContentsActionsEnum, any>,
) => {
  loggerInfo('CategoryContentsContext', 'categoryContentsReducer', { state, action });
  switch (action.type) {
    case CategoryContentsActionsEnum.LOAD_DATA:
      return { ...state, category: action.payload };
    case CategoryContentsActionsEnum.CHANGE_SEARCH_TERM:
      return { ...state, searchTerm: action.payload };
    case CategoryContentsActionsEnum.CLEAR_SECTIONS_OFFSET:
      return { ...state, sectionOffset: {} };
    case CategoryContentsActionsEnum.APPEND_SECTION_OFFSET:
      return {
        ...state,
        sectionOffset: {
          ...state.sectionOffset,
          [action.payload.id]: action.payload.offset,
        },
      };
    case CategoryContentsActionsEnum.SET_LOADING:
      return { ...state, loading: action.payload };
    case CategoryContentsActionsEnum.CLEAR:
      return { ...state, ...initialCategoryContentsState };
    case CategoryContentsActionsEnum.LOAD_CONTENT:
      return buildContentInSubCategory(state, action.payload);
    default:
      return state;
  }
};
