import { useEffect } from 'react';
import { IUserLocalStorage } from '@shared/utils/user';
import UserLocalStorage from '@local-storage/user';

const status = ['N/D', 'Assinante', 'Nao Assinante'];
const medicalFormation = ['N/D', 'Generalista', 'Residente ou Pos Graduado', 'Especialista'];

declare global {
  interface Window {
    wootric;
  }
}

export function Wootric() {
  useEffect(() => {
    init();
  }, []);

  const init = (): void => {
    const user = UserLocalStorage.get();
    if (!user || !user.id || document.getElementById('wootric-settings') !== null) return;
    createdSetupScript(user);
    initScript();
  };

  const createdSetupScript = (user: IUserLocalStorage) => {
    const setupScript = document.createElement('script');
    setupScript.type = 'text/javascript';
    setupScript.id = 'wootric-settings';
    setupScript.async = true;
    setupScript.innerHTML = `
      wootric_no_surveyed_cookie = '${process.env.NEXT_PUBLIC_WOOTRIC_SURVEY_IMMEDIATELY}'
      wootric_survey_immediately = '${process.env.NEXT_PUBLIC_WOOTRIC_NO_SURVEYED_COOKIE}'
      window.wootricSettings = {
        wootric_recommend_target : "um colega de profissão",
        email : '${user.email}',
        properties: {
            status : '${status[user.typeId] ?? 'N/D'}',
            Grau_de_Formacao : '${user.formationDegree ?? 'N/D'}',
            Area_De_Atuacao : '${user.occupationArea ?? 'N/D'}',
            Formacao_Medica : '${medicalFormation[user.medicalFormationId] ?? 'N/D'}',
            Especialidade : '${user.specialty ?? 'N/D'}',
            UF : '${user.uf ?? 'N/D'}',
           plataforma : '${process.env.NEXT_PUBLIC_WOOTRIC_PLATFORM}',
           Versao_do_App : '${process.env.NEXT_PUBLIC_APP_NAME}@ ${process.env.NEXT_PUBLIC_APP_VERSION}'
       },
       account_token: '${process.env.NEXT_PUBLIC_WOOTRIC_ACCOUNT_TOKEN}',
       external_id: '${user.id}',
       created_at: '${(new Date(user.registerDate).getTime() / 1000).toFixed(0)}',
      };`;

    document.body.appendChild(setupScript);
  };

  const initScript = () => {
    const beacon = document.createElement('script');
    beacon.type = 'text/javascript';
    beacon.id = 'wootric-beacon';
    beacon.async = true;

    beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
    beacon.onload = () => {
      window.wootric('run');
    };
    if (document.getElementById('wootric-beacon') == null) {
      document.body.appendChild(beacon);
    }
  };
  return <div />;
}
