import { DataCode } from '@remote-data/load-codes/types';

export enum SearchCodesActionsEnum {
  LOAD_RESULTS = '@searchCodes/LOAD_RESULTS',
  SET_LOADING = '@searchCodes/SET_LOADING',
  CHANGE_SEARCH_TERM = '@searchCodes/CHANGE_SEARCH_TERM',
}

export type SearchCodesState = {
  results?: DataCode[];
  loading?: boolean;
  searchTerm?: string;
  codeType?: CodeTypes | null;
};

export enum CodeTypes {
  CID10 = 'CID10',
  SUS = 'SUS',
  TUSS = 'TUSS',
}
