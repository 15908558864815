import { ApplicationActionType } from '@context/root-reducers/types';
import { SignUpLoginActionsEnum, SignUpLoginState } from '@context/sign-up-login/types';

export const initialSignUpLoginState: SignUpLoginState = {
  isSignUp: false,
  loginValue: '',
  highlightSignIn: false,
};

export const signUpLoginReducer = (
  state: SignUpLoginState = initialSignUpLoginState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: ApplicationActionType<SignUpLoginActionsEnum, any>,
) => {
  switch (action.type) {
    case SignUpLoginActionsEnum.SET_IS_SIGN_UP:
      return { ...state, isSignUp: action.payload };
    case SignUpLoginActionsEnum.SET_LOGIN_VALUE:
      return { ...state, loginValue: action.payload };
    case SignUpLoginActionsEnum.SET_HIGHLIGHT_SIGN_IN:
      return { ...state, highlightSignIn: action.payload };
    case SignUpLoginActionsEnum.SET_LOGIN_METHOD:
      return { ...state, loginMethod: action.payload };
    default:
      return state;
  }
};
