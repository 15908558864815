import { analyticsHelper } from '@analytics/helpers/analytics';
import { isNil, omitBy } from 'lodash';

export const sendTrack = (
  data: object,
  eventName: string,
  type: string | null = null,
  excludeEntities?: string[],
  shouldOmitNil = true,
): void => {
  const params = shouldOmitNil
    ? omitBy(
        {
          type,
          ...data,
        },
        isNil,
      )
    : data;
  analyticsHelper().track('snowplow', eventName, params, excludeEntities);
};

export const sendEntity = (data: object, eventName: string): void => {
  const params = {
    ...data,
  };

  analyticsHelper().entity('snowplow', eventName, params);
};
