import { initFirebase } from '@configs/firebase';
import { RemoteConfigKeys, isFreeUserExclusive } from '@hooks/use-remote-config/types';
import { getApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import User from '@shared/utils/user';

export default class RemoteConfig {
  static async initRemoteConfig(defaultValues) {
    const app = initFirebase();

    const remoteConfig = getRemoteConfig(app);
    remoteConfig.defaultConfig = defaultValues;

    return remoteConfig;
  }

  static getInstanceRemoteConfig() {
    const app = getApp('wbweb');

    return getRemoteConfig(app);
  }

  static async getValuesRemoteConfig() {
    await fetchAndActivate(RemoteConfig.getInstanceRemoteConfig());
    let keys = RemoteConfigKeys;
    if (User.isUserPremium()) {
      keys = keys.filter((val) => !isFreeUserExclusive.includes(val));
    }

    const result = Object.fromEntries(
      keys.map((remoteKey) => [
        remoteKey,
        JSON.parse(getValue(RemoteConfig.getInstanceRemoteConfig(), remoteKey).asString()),
      ]),
    );

    return result;
  }
}
