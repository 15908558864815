import AuthLocalStorage from '@local-storage/auth';
import LocalStorage from '@local-storage/index';
import { OnboardingLocalStorage } from '@local-storage/onboarding';
import { KeysLocalStorageEnum } from '@local-storage/types';
import { yellowSeptemberLocalStorage } from '@local-storage/yellow-september';
import { decodeToken } from '@shared/auth/utils';
import Cookie from '@shared/utils/cookie';
import GlobalsCookie from '@shared/utils/cookie/globals';

class Auth {
  isLoggedIn(): boolean {
    const refreshToken = this.getRefreshToken();
    const accessToken = this.getToken();

    if (
      (!refreshToken && !accessToken) ||
      (this.isRefreshTokenExpired(refreshToken) && this.isTokenExpired(accessToken))
    ) {
      return false;
    }

    return true;
  }

  getToken(): string {
    const globals = GlobalsCookie.get();
    return globals?.authData || LocalStorage.get(KeysLocalStorageEnum.TOKEN_AUTH);
  }

  getRefreshToken(): string {
    const globals = GlobalsCookie.get();
    return globals?.refreshToken || AuthLocalStorage.getRefreshToken();
  }

  isTokenExpired(jwtToken: string): boolean {
    const decodedPayload = decodeToken(jwtToken);

    if (typeof decodedPayload === 'boolean') return true;

    const expirationDate = decodedPayload.exp * 1000;

    const newExpirationDate = AuthLocalStorage.getNewTimeToRefreshToken();

    if (newExpirationDate) {
      return new Date() >= new Date(newExpirationDate);
    }

    return new Date() >= new Date(expirationDate);
  }

  isRefreshTokenExpired(jwtToken: string) {
    const decodedPayload = decodeToken(jwtToken);

    if (typeof decodedPayload === 'boolean') return true;

    const expirationDate = decodedPayload.exp * 1000;

    return new Date() >= new Date(expirationDate);
  }

  logout() {
    const allOnboardingLocalStorage = OnboardingLocalStorage.getAll();
    const allYellowSeptemberLocalStorage = yellowSeptemberLocalStorage.getAll();

    LocalStorage.clear();
    new Cookie().clearAll();

    OnboardingLocalStorage.setAll(allOnboardingLocalStorage);
    yellowSeptemberLocalStorage.setAll(allYellowSeptemberLocalStorage);
  }
}

export default new Auth();
