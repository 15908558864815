import { HttpResponse } from '@remote-data/http-client/types';
import { UserSubscriptionModel } from '@remote-data/load-subscription/types';
import UseCaseCheckSubscription from '@use-cases/check-subscription';

export const makeNotSubscriptionModel = ({
  statusCode,
  body,
}: HttpResponse<{
  statusCode: string;
  data: UserSubscriptionModel;
}>): HttpResponse<UserSubscriptionModel> => {
  return {
    statusCode,
    body: {
      subscription: {
        active: false,
        trial: UseCaseCheckSubscription.availableForTrial(body?.data?.subscriptions),
      },
      freeTasting: {
        active: false,
      },
      userId: body.data.user.id,
    },
  };
};

export const apiSubscriptionToModel = ({
  statusCode,
  body: { data },
}: HttpResponse): HttpResponse<UserSubscriptionModel> => {
  return {
    statusCode,
    body: {
      ...data,
    },
  };
};
