import { KeysLocalStorageEnum } from '@local-storage/types';
import LocalStorage from '@local-storage/index';

class AuthLocalStorage {
  private AUTH_KEY = KeysLocalStorageEnum.AUTH;
  private AUTH_TOKEN_KEY = KeysLocalStorageEnum.TOKEN_AUTH;
  private TIME_TO_REFRESH_TOKEN_KEY = KeysLocalStorageEnum.TIME_TO_REFRESH_TOKEN;
  private REFRESH_TOKEN_KEY = 'refreshToken';

  getRefreshToken(): string {
    return LocalStorage.get(this.AUTH_KEY)?.[this.REFRESH_TOKEN_KEY];
  }

  getToken(): string {
    return LocalStorage.get(this.AUTH_TOKEN_KEY);
  }

  setRefreshToken(value) {
    LocalStorage.set(this.AUTH_KEY, { [this.REFRESH_TOKEN_KEY]: value });
  }

  setToken(value) {
    LocalStorage.set(this.AUTH_TOKEN_KEY, value);
  }

  setNewTimeToRefreshToken(value) {
    LocalStorage.set(this.TIME_TO_REFRESH_TOKEN_KEY, value);
  }

  getNewTimeToRefreshToken() {
    return LocalStorage.get(this.TIME_TO_REFRESH_TOKEN_KEY);
  }

  clearNewTimeToRefreshToken() {
    localStorage.removeItem(this.TIME_TO_REFRESH_TOKEN_KEY);
  }

  clearAll() {
    localStorage.removeItem(this.AUTH_KEY);
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
  }
}

export default new AuthLocalStorage();
