import { KeysLocalStorageEnum } from '@local-storage/types';

export class LocalStorage {
  set(key: KeysLocalStorageEnum, value): void {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
  }

  get(key: KeysLocalStorageEnum) {
    if (!this.isReady()) return null;

    return JSON.parse(localStorage.getItem(key));
  }

  clear(): boolean {
    if (!this.isReady()) return null;

    localStorage.clear();
    return false;
  }

  isReady(): boolean {
    return typeof window !== 'undefined';
  }
}
export default new LocalStorage();
