export type CheckSubscription = {
  status?: string;
  gracePeriodEndDate?: string;
  isPending: boolean;
  isPremium: boolean;
  id: number;
  userId: number;
  paymentMethod?: string;
  paidValue?: string;
  gatewayName?: string;
  expirationDate?: string;
  installmentValue?: string;
  installmentsQuantity?: number;
  period?: string;
};

export enum StatusSubscription {
  Approved = 'APROVADA',
  Suspended = 'SUSPENSA',
  Pending = 'PENDENTE',
  Paused = 'PAUSADA',
  Contested = 'CONTESTADA',
  Refunded = 'REEMBOLSADA',
  Canceled = 'CANCELADA',
  PreAuthorized = 'PRE-AUTORIZADA',
}
