import { FavoritesActionsEnum, FavoritesState } from '@context/favorites/types';
import { ApplicationActionType } from '@context/root-reducers/types';

export const initialFavoritesState: FavoritesState = {
  chosenFavorites: [],
  availableCategories: [],
  originalAvailableCategories: [],
};

export const favoritesReducer = (
  state: FavoritesState = initialFavoritesState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: ApplicationActionType<FavoritesActionsEnum, any>,
) => {
  return { ...state, ...action.payload };
};
