import { colors } from '@styles/tokens/colors';
import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  tokens: {
    ...colors,
  },
  medfriday: {
    renewal: {
      backgroundDark: '#000',
      background: '#010028',
      color: '#FFFFFF',
      accent: '#5457C6',
    },
    subscription: {
      backgroundDark: '#040404',
      background: '#010028',
      color: '#FFFFFF',
      accent: '#5457C6',
    },
  },
};
