import { CategoryModel } from '@models/category-model';
import { ContentModel } from '@models/content-model';

export enum CategoryContentsActionsEnum {
  LOAD_DATA = '@categoryContents/LOAD_DATA',
  CHANGE_SEARCH_TERM = '@categoryContents/CHANGE_SEARCH_TERM',
  CLEAR_SECTIONS_OFFSET = '@categoryContents/CLEAR_SECTIONS_OFFSET',
  APPEND_SECTION_OFFSET = '@categoryContents/APPEND_SECTION_OFFSET',
  SET_LOADING = '@categoryContents/SET_LOADING',
  CLEAR = '@categoryContents/CLEAR',
  LOAD_CONTENT = '@categoryContents/LOAD_CONTENT',
}

export type CategoryContentsState = {
  category: CategoryModel;
  content: ContentModel;
  searchTerm: string;
  loading?: boolean;
  sectionOffset: {
    [key: number]: number;
  };
};

export type CategoryContentsActionsType = {
  type: CategoryContentsActionsEnum;
  payload;
};
