declare global {
  interface Window {
    abortControllerInstance: any;
  }
}

export class AbortControllerInstance {
  static set() {
    window.abortControllerInstance = new AbortController();
  }

  static get() {
    return window.abortControllerInstance;
  }

  static signal() {
    return this.get().signal;
  }

  static abort() {
    this.get().abort();
  }

  static clear() {
    window.abortControllerInstance = null;
  }
}
