const oldRoutesToNewRoutes = [
  {
    from: '/editar_cadastro',
    to: '/perfil',
  },
  {
    from: '/medphone',
    to: '/',
  },
  {
    from: '/cid-10',
    to: '/cid10',
  },
  {
    from: '/cupom/ativar',
    to: '/perfil/codigo-acesso',
  },
];

export const redirectRoutes = [
  {
    from: '/login',
    to: '/home',
  },
  {
    from: '/medfriday',
    to: '/',
  },
  {
    from: '/lp-renovacao',
    to: '/parceria-renovada',
  },
  {
    from: '',
    to: '/home',
  },
  {
    from: '/planos',
    to: '/home',
  },
  ...oldRoutesToNewRoutes,
];
