export const getBrowserNome = () => {
  const { userAgent } = navigator;
  if (userAgent.includes('Chrome')) return 'Chrome';
  if (userAgent.includes('Safari')) return 'Safari';
  if (userAgent.includes('Opera')) return 'Opera';
  if (userAgent.includes('Firefox')) return 'Firefox';
  if (userAgent.includes('MSIE')) return 'IE';
  if (userAgent.includes('AppleWebKit')) return 'AppleWebKit';
  return 'Outros';
};

export const getBrowserVersion = () => {
  const browserNome = getBrowserNome();
  const { userAgent } = navigator;
  if (browserNome === 'Outros') return 'Outros';
  return userAgent.slice(userAgent.indexOf(browserNome)).split('/')[1].split(' ')[0];
};

export const isDevelopment = () => process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'DEVELOPMENT';
export const isProduction = () => process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'PRODUCTION';
export const isLocalhost = () => window?.location?.protocol === 'http:';

export const cookieDomain = () => {
  if (isLocalhost()) {
    return 'localhost';
  }
  return '.pebmed.com.br';
};
