import { GoogleAdsActionTypes } from '@context/google-ads/actions';
import { GoogleAdsActionsEnum, GoogleAdsState } from '@context/google-ads/types';

export const initialGoogleAdsState: GoogleAdsState = { isEmpty: true, isLoading: false, targeting: [] };

export const googleAdsReducer = (state: GoogleAdsState = initialGoogleAdsState, action: GoogleAdsActionTypes) => {
  switch (action.type) {
    case GoogleAdsActionsEnum.SET_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case GoogleAdsActionsEnum.SET_IS_EMPTY:
      return { ...state, isEmpty: action.isEmpty };
    case GoogleAdsActionsEnum.SET_TARGETING:
      return { ...state, targeting: action.targeting };
    default:
      return state;
  }
};
