import { HttpStatusCode } from '@remote-data/http-client/types';
import { sentryCaptureException } from '@configs/sentry';

export class RemoteError extends Error {
  statusCode: HttpStatusCode;
  code: string;
  message: string;

  constructor(statusCode: HttpStatusCode, code: string, message: string) {
    super(message);
    this.statusCode = statusCode;
    this.code = code;
    this.message = message;
    this.sendToSentry(statusCode, message);
  }

  private sendToSentry(statusCode: HttpStatusCode, message: string) {
    if (!statusCode) return;

    const errorMessage = message || 'Error';
    const error = new Error(errorMessage);

    // @ts-ignore
    error.statusCode = statusCode;

    sentryCaptureException(error, {
      tags: {
        section: `${statusCode}`,
      },
    });
  }
}
